import React, { useEffect, useState } from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from 'react-lazyload';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Header from '../components/Header';
import Page from '../components/Page';
import Location from '../components/Location';
import Footer from '../components/Footer';
import ShortAbout from '../components/ShortAbout';

import styles from './careers.module.css';

// Shuffle function
function shuffle(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

function renderTeamMember({ name, title, image, email }) {
  return (
    <li key={name} className={styles.item}>
      <LazyLoad>
        <img
          className={styles.avatar}
          width="160"
          height="160"
          src={image || '/images/team/placeholder.png'}
          alt={`${name} - ${title}`}
        />
      </LazyLoad>
      <span className={styles.name}>{name}</span>
      <span className={styles.title}>{title}</span>
    </li>
  );
}

const CareersPage = ({ data }) => {
  const [shuffledTeam, setShuffledTeam] = useState(null);

  const team = data.allTeamJson.edges.map(({ node }) => ({
    ...node,
  }));

  useEffect(() => {
    /**
     * Shuffle the team page.
     */
    setShuffledTeam(shuffle(team));
  }, [data]);

  return (
    <div>
      <Helmet
        title={`${data.site.siteMetadata.title} - Careers`}
        meta={[
          {
            name: 'description',
            content:
              'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
          },
          {
            name: 'keywords',
            content:
              'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
          },
        ]}
      >
        <html lang="sv" />
        <link rel="icon" href="/favicon.ico?v=2" />
      </Helmet>
      <div className={styles.container}>
        <Header
          className={styles.headerBackground}
          title={data.site.siteMetadata.title}
        />
        <Page bgColor="#F8F6FA" className={styles.careersPage}>
          <section>
            <h1>Careers</h1>
            <h2>Join North Link and create the future you want to live in</h2>
            <p>
              <Link to="#openings">
                <button>Openings</button>
              </Link>
              <Link to="#location">
                <button>Connect</button>
              </Link>
            </p>
          </section>
          <main>
            <div className={styles.line} />
            <section className={styles.header}>
              <p>
                We're constantly looking for talent and great co-workers,
                currently residing in Helsingborg with remote-work available.
                Feel free to drop us a line if there's a position that looks
                enticing or if you just want to have a quick chat.
              </p>
            </section>
            <section className={styles.offer}>
              <h3>What we offer</h3>
              <h4>About North link</h4>
              <p>
                At North Link we are specialised in solving complicated software
                problems with innovative solutions. We're a team of experts in
                machine learning, computer vision and software development.
              </p>
              <h4>Our business model is simple</h4>
              <p>
                We build knowledge together and then share this knowledge with
                our customers.
              </p>

              <h4>We are a knowledge driven company</h4>
              <p>
                Use cases for machine learning has exploded during the last
                couple of years and we see it as given to meet the future both
                through academic developments and also in practice.
              </p>
              <p>
                <br />
                For us to keep up and be able to do this, we have internal
                presentations and workshops monthly where we share knowledge and
                test new technologies and we are always looking for technically
                challenging projects.
              </p>
              <h4>We choose participation</h4>
              <p>
                Our beautiful and modern new office is located in the city
                center of Helsingborg, only a five minute walk from Helsingborg
                C. Some of our projects are being performed from our office, and
                some are being performed onsite at the customers. We invest a
                lot of time in teambuilding to make everyone feel included and
                to make everyone comfortable to take on complex challenges.
              </p>

              <h4>We offer involvment</h4>
              <p>
                At North Link we all have the opportunity to take big
                responsibility and to decide how we wish to work. We have a
                variety of customers, mostly in Skåne, and you play a big role
                in deciding what projects that are interesting for you and that
                you want to join.
              </p>
              <Link to="#location">
                <button>Connect with us >></button>
              </Link>
            </section>
            <section className={styles.team}>
              <h3>Say hi to team North Link</h3>
              <ul className={styles.list}>
                {(shuffledTeam || team).slice(0, 4).map(renderTeamMember)}
              </ul>
              <Link to="/the-team">
                <button>More People >></button>
              </Link>
            </section>

            <section className={styles.testimonialsContainer}>
              <h3>What does North Link members have to say?</h3>
              <div className={styles.testimonials}>
                <div>
                  <LazyLoad>
                    <img src="/images/testimonials/mattias.jpg" />
                  </LazyLoad>
                  <p className={styles.testimonialQuote}>
                    - "I have always enjoyed tackling challenging problems. That
                    lead me into the field of academia, and a Ph.D. in an
                    engineering and mathematics-focused field. Even though
                    coding has always been a crucial tool for me, I was not sure
                    that I was ready to leave academia. However, during my time
                    here, I have had the chance to work on very interesting
                    problems and solve them using the tools and methods that I
                    am used to. It is now clear to me; that this is a company
                    that values knowledge, experience, and good ideas, and I am
                    convinced that I will be able to continue working on
                    interesting and challenging problems here."
                  </p>
                  <p className={styles.testimonialAuthor}>
                    Mattias Fält, Ph.D.
                  </p>
                  <p className={styles.testimonialTitle}>Data scientist</p>
                </div>
                <div>
                  <LazyLoad>
                    <img src="/images/testimonials/micke.jpg" />
                  </LazyLoad>
                  <p className={styles.testimonialQuote}>
                    - "Working at North Link has been a tremendous joy. The
                    colleagues here inspire a good variety of interesting
                    discussions, and some of those result in fun projects. It is
                    a good mix of professional topics and talking about hobbies.
                    Since starting work here, I have learned and grown into a
                    better developer using current frameworks and tools."
                  </p>
                  <p className={styles.testimonialAuthor}>Michael Hansen</p>
                  <p className={styles.testimonialTitle}>Software developer</p>
                </div>
                <div>
                  <LazyLoad>
                    <img src="/images/testimonials/andy.jpg" />
                  </LazyLoad>
                  <p className={styles.testimonialQuote}>
                    - "To me the most important aspect of a workplace is a good
                    relationship with my colleagues. That and that there is a
                    lot of headroom for personal growth with possibilities for
                    creative freedom to create amazing things together. At North
                    Link we have the whole trifecta. Where no issue or problem
                    is too big nor little. We focus on building a work
                    environment where we help each other without prestige. We
                    grow and solve challenging problems and have a lot of fun
                    whilst doing so."
                  </p>
                  <p className={styles.testimonialAuthor}>Andreas Nilsson</p>
                  <p className={styles.testimonialTitle}>Art director</p>
                </div>
              </div>
            </section>

            <section className={styles.why}>
              <h3>Why North Link?</h3>
              <div>
                <div className={styles.whyIllustration}>
                  <img src="/images/nl_cases.svg" />
                </div>
                <div className={styles.whyText}>
                  <div>
                    <div>
                      <p>
                        We work with some of the most fun projects and eccentric
                        ideas. Where else would you build a chess notation
                        software?
                      </p>
                      <div className={styles.lineWhite} />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="openings" className={styles.openings}>
              <h3>Job Openings</h3>
              <div>
                {data.allOpeningsJson.edges
                  .map((x) => x.node)
                  .map((opening) => (
                    <Link
                      to={`/careers/${opening.uri}`}
                      key={opening.uri}
                      className={styles.opening}
                      style={{ backgroundImage: `url(${opening.image})` }}
                    >
                      <div className={styles.openingBorder} />
                      <div className={styles.openingContent}>
                        <h4>{opening.title}</h4>
                        <p>
                          {opening.tags.map((tag, i) => (
                            <React.Fragment key={i}>
                              {i != 0 ? ' · ' : ''}
                              {tag}
                            </React.Fragment>
                          ))}
                        </p>
                      </div>
                    </Link>
                  ))}
              </div>
            </section>

            <h3>Gallery</h3>
            <section className={styles.gallery}>
              <LazyLoad>
                <div>
                  <img src="/images/careers/IMG_20220506_124236.jpg" />
                </div>
                <div>
                  <img src="/images/careers/PXL_20221202_195348967.MP.jpg" />
                  <img src="/images/careers/PXL_20230525_161715225.jpg" />
                </div>
                <div>
                  <img src="/images/careers/IMG_20210930_125829.jpg" />
                </div>
                <div>
                  <img src="/images/careers/IMG_2644.jpg" />
                  <img src="/images/careers/PXL_20230824_181202338.MP.jpg" />
                </div>
              </LazyLoad>
            </section>

            <ShortAbout numEmployees={data.allTeamJson.edges.length} />
          </main>
        </Page>
        <Location title={data.site.siteMetadata.title} id="location" />
        <Footer
          products={data.allProductsJson.edges.map((x) => x.node)}
          cases={data.allCasesJson.edges.map((x) => x.node)}
          title={data.site.siteMetadata.title}
        />
      </div>
    </div>
  );
};

export default CareersPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allTeamJson {
      edges {
        node {
          name
          title
          image
          email
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allOpeningsJson {
      edges {
        node {
          title
          uri
          image
          tags
        }
      }
    }
  }
`;

import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

export default function ShortAbout({ numEmployees }) {
  return (
    <section className={styles.about}>
      <div>
      <h3>About North Link</h3>
      <p>
    North Link is a knowledge driven software company that was founded in Helsingborg, Sweden by the three childhood friends Niklas Silfverström, Viktor Silfverström and Andreas Argelius together with Johan Lenander in 2017. We like to tackle hard to solve problems. Many of our projects involve machine learning and computer vision. 
      </p>
      <div className={styles.stats}>
        <p>
          Founded in <span>2017</span>
        </p>
        <p>
          Co-workers<span>{numEmployees}</span>
        </p>
      </div>
    </div>
    </section>
  );
}
